const initialState = {
  firebaseDocumentId: "",
  demographics: {},
  quiz1: {},
  quiz2: {},
  vulva: {
    monspubis: "1",
    outerLabia: "1",
    innerLabia: "1",
    urethra: "1",
    clitoris: "1",
    clitoralHoods: "1",
    piercings: "1",
    // backgrounds: "1",
    anus: "1",
    vagina: "1",
  },
}

const ADD_FB_DOCUMENT_ID = "ADD_FB_DOCUMENT_ID"
const ADD_DEMOGRAPHICS = "ADD_DEMOGRAPHICS"
const ADD_QUIZ_1_DATA = "ADD_QUIZ_1_DATA"
const ADD_QUIZ_2_DATA = "ADD_QUIZ_2_DATA"
const ADD_QUIZ_3_DATA = "ADD_QUIZ_3_DATA"
const UPDATE_VULVA_DESIGN = "UPDATE_VULVA_DESIGN"
const REPLACE_VULVA_DESIGN = "REPLACE_VULVA_DESIGN"

export const addFbDocumentId = firebaseDocumentId => ({
  type: ADD_FB_DOCUMENT_ID,
  firebaseDocumentId,
})

export const addDemographics = demographics => ({
  type: ADD_DEMOGRAPHICS,
  demographics,
})

export const addQuiz1Data = data => ({
  type: ADD_QUIZ_1_DATA,
  data,
})

export const addQuiz2Data = data => ({
  type: ADD_QUIZ_2_DATA,
  data,
})

export const addQuiz3Data = data => ({
  type: ADD_QUIZ_3_DATA,
  data,
})

export const updateVulvaDesign = data => ({
  type: UPDATE_VULVA_DESIGN,
  data,
})
export const replaceVulvaDesign = data => ({
  type: REPLACE_VULVA_DESIGN,
  data,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FB_DOCUMENT_ID:
      return { ...state, firebaseDocumentId: action.firebaseDocumentId }
    case ADD_DEMOGRAPHICS:
      return { ...state, demographics: action.demographics }
    case ADD_QUIZ_1_DATA:
      return { ...state, quiz1: action.data }
    case ADD_QUIZ_2_DATA:
      return { ...state, quiz2: action.data }
    case ADD_QUIZ_3_DATA:
      return { ...state, quiz2: action.data }
    case UPDATE_VULVA_DESIGN:
      const [part, variant] = action.data
      return {
        ...state,
        vulva: {
          ...state.vulva,
          [part]: variant,
        },
      }
    case REPLACE_VULVA_DESIGN:
      return {
        ...state,
        vulva: action.data,
      }
    default:
      return state
  }
}

// {}

// {
//   type: "UPDATE_VULVA_DESIGN",
//   data:
// }

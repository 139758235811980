// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-about-js": () => import("./../../../src/pages/intro/about.js" /* webpackChunkName: "component---src-pages-intro-about-js" */),
  "component---src-pages-intro-index-js": () => import("./../../../src/pages/intro/index.js" /* webpackChunkName: "component---src-pages-intro-index-js" */),
  "component---src-pages-intro-next-step-js": () => import("./../../../src/pages/intro/next-step.js" /* webpackChunkName: "component---src-pages-intro-next-step-js" */),
  "component---src-pages-intro-terms-js": () => import("./../../../src/pages/intro/terms.js" /* webpackChunkName: "component---src-pages-intro-terms-js" */),
  "component---src-pages-know-your-vulva-hotspot-js": () => import("./../../../src/pages/know-your-vulva/hotspot.js" /* webpackChunkName: "component---src-pages-know-your-vulva-hotspot-js" */),
  "component---src-pages-know-your-vulva-index-js": () => import("./../../../src/pages/know-your-vulva/index.js" /* webpackChunkName: "component---src-pages-know-your-vulva-index-js" */),
  "component---src-pages-know-your-vulva-next-step-js": () => import("./../../../src/pages/know-your-vulva/next-step.js" /* webpackChunkName: "component---src-pages-know-your-vulva-next-step-js" */),
  "component---src-pages-know-your-vulva-results-js": () => import("./../../../src/pages/know-your-vulva/results.js" /* webpackChunkName: "component---src-pages-know-your-vulva-results-js" */),
  "component---src-pages-know-your-vulva-summary-js": () => import("./../../../src/pages/know-your-vulva/summary.js" /* webpackChunkName: "component---src-pages-know-your-vulva-summary-js" */),
  "component---src-pages-love-your-vulva-next-step-js": () => import("./../../../src/pages/love-your-vulva/next-step.js" /* webpackChunkName: "component---src-pages-love-your-vulva-next-step-js" */),
  "component---src-pages-love-your-vulva-outro-js": () => import("./../../../src/pages/love-your-vulva/outro.js" /* webpackChunkName: "component---src-pages-love-your-vulva-outro-js" */),
  "component---src-pages-love-your-vulva-quiz-js": () => import("./../../../src/pages/love-your-vulva/quiz.js" /* webpackChunkName: "component---src-pages-love-your-vulva-quiz-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-vulva-health-index-js": () => import("./../../../src/pages/vulva-health/index.js" /* webpackChunkName: "component---src-pages-vulva-health-index-js" */),
  "component---src-pages-vulva-health-next-step-js": () => import("./../../../src/pages/vulva-health/next-step.js" /* webpackChunkName: "component---src-pages-vulva-health-next-step-js" */),
  "component---src-pages-vulva-health-outro-js": () => import("./../../../src/pages/vulva-health/outro.js" /* webpackChunkName: "component---src-pages-vulva-health-outro-js" */),
  "component---src-pages-vulva-health-quiz-js": () => import("./../../../src/pages/vulva-health/quiz.js" /* webpackChunkName: "component---src-pages-vulva-health-quiz-js" */),
  "component---src-pages-vulva-maker-finished-js": () => import("./../../../src/pages/vulva-maker/finished.js" /* webpackChunkName: "component---src-pages-vulva-maker-finished-js" */),
  "component---src-pages-vulva-maker-index-js": () => import("./../../../src/pages/vulva-maker/index.js" /* webpackChunkName: "component---src-pages-vulva-maker-index-js" */),
  "component---src-pages-vulva-maker-maker-js": () => import("./../../../src/pages/vulva-maker/maker.js" /* webpackChunkName: "component---src-pages-vulva-maker-maker-js" */)
}

